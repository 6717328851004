import React from "react";
import { TdsButton, TdsIcon, TdsTypography } from "@tds/react";
import logo from "../images/ThalesLogo.png";
import { Trans, useTranslation } from "react-i18next";
import { getMonthRange } from "../utils/mcdUtils";
import { changeLanguage } from "i18next";
import useLayoutType from "../hooks/useLayoutType";
import logoPulse from "../images/pulsetrackerlogo.svg";
import styled from "@emotion/styled";
import { getZoom } from "../utils/sizeUtils";

const StyledHeader = styled("header")(({ theme }) => {
  let zoom = getZoom();
  return {
    ".logo": {
      zoom: `${zoom}%`,
    },
    ".zoom": {
      zoom: `${zoom}%`,
    },
    ".en": {
      zoom: `${zoom}%`,
    },
    ".fr": {
      zoom: `${zoom}%`,
    },
  };
});

export default function Header({ step }) {
  const { i18n } = useTranslation();
  const year = new Date().getFullYear();
  const layoutType = useLayoutType();
  return (
    <StyledHeader>
      <div id="header" className="mdc-header">
        <div className="header-elements">
          <img className="logo" src={logo} />
          {step === 1 && (
            <div className="language-buttons">
              <TdsIcon classes="zoom text-white-100" icon="language"></TdsIcon>
              <TdsButton
                id="btnEn"
                classes={`${i18n.language === "en" && "underline"} en`}
                onClick={() => changeLanguage("en")}
              >
                EN
              </TdsButton>
              /
              <TdsButton
                id="btnFr"
                classes={`${i18n.language === "fr" && "underline"} fr`}
                onClick={() => changeLanguage("fr")}
              >
                FR
              </TdsButton>
            </div>
          )}
        </div>

        {step === 1 && (
          <>
            <TdsTypography fontSize={5} classes="zoom title">
              <p>
                {getMonthRange(i18n.language)} {year}
              </p>
            </TdsTypography>
            <TdsTypography fontSize={5} classes="zoom title mb-m">
              <Trans i18nKey="title"></Trans>
            </TdsTypography>
            <div className="subtitle">
              <div className="subtitle-text">
                <TdsTypography classes="zoom">
                  <Trans i18nKey="welcome"></Trans>
                </TdsTypography>
                <TdsTypography classes="zoom">
                  <Trans i18nKey="email"></Trans>
                  <a href="mailto:tce@thalesgroup.com" className="email">
                    tce@thalesgroup.com
                  </a>
                </TdsTypography>
              </div>
            </div>
          </>
        )}
        <div className={`${step === 2 ? "container-second" : ""}`}>
          {step === 2 && (
            <div id="second-header" className="mdc-header2">
              <TdsTypography fontSize={5} classes="zoom title">
                <p>
                  {getMonthRange(i18n.language)} {year}
                </p>
              </TdsTypography>
              <div className="pulse">
                <TdsTypography fontSize={5} classes="zoom title">
                  <Trans i18nKey="title"></Trans>
                </TdsTypography>
              </div>
            </div>
          )}
          {layoutType === "desktop" && (
            <img
              className={`${
                step === 1 ? "zoom logo-pulse-first" : " zoom logo-pulse-second"
              }`}
              src={logoPulse}
            />
          )}
        </div>
      </div>
    </StyledHeader>
  );
}
