import React from "react";
import { createRoot } from "react-dom/client";
import pulseTracker from "../images/pulseTracker.svg";
import CookiesLayout from "../components/Layout/CookiesLayout/CookiesLayout";
import "../styles/base.css";
import {
  StyledCookiesContainer,
  StyledCookiesWarning,
  StyledCookiesStep,
  StyledCookiesLogo,
} from "../components/Layout/CookiesLayout/styled";
import McdTypography from "../common/McdTypography";
import McdIcon from "../common/McdIcon";

const container = document.getElementById("root");
const root = createRoot(container);
const language = window.navigator.language;

let firstSubtitle = "";
let secondText = "";
let firstStep = "";
let firstAdditionalStep = "";
let secondStep = "";
let thirdStep = "";
let fourthStep = "";

const browserType = (function (agent) {
  if (language.includes("fr")) {
    firstSubtitle = "Il semble que vos cookies soient désactivés.";
    secondText =
      "Pour utiliser pulseTracker, veuillez suivre les étapes suivantes:";
    firstStep = "Cliquez sur le bouton Menu";
    firstAdditionalStep =
      "situé dans le coin supérieur droit du navigateur, puis cliquez sur Paramètres.";
    secondStep =
      "Cliquez sur Cookies et autorisations du site, puis sur Gérer et supprimer les cookies et les données du site.";
    thirdStep =
      "Choisissez l'option Autoriser les sites à enregistrer et lire les données des cookies.";
    fourthStep = "Actualisez la page (CTRL + F5).";
  } else {
    firstSubtitle = "Looks like your Cookies are disabled.";
    secondText = "In order to use pulseTracker, please follow the steps:";
    firstStep = "Click the Menu button";
    firstAdditionalStep =
      "found at the top-right corner of the browser, and then click Settings.";
    secondStep =
      "Click Cookies and site permissions and then Manage and delete cookies and site data.";
    thirdStep = "Choose the option Allow sites to save and read cookie data.";
    fourthStep = "Refresh the page (CTRL + F5).";
  }

  if (!(agent.indexOf("edg/") > -1)) {
    if (language.includes("fr")) {
      firstSubtitle = "Il semble que vous n'utilisez pas Microsoft Edge.";
      secondText =
        "Pour une meilleure expérience d'utilisation de pulseTracker, nous recommandons Microsoft Edge et l'activation des cookies:";
    } else {
      firstSubtitle = "Seems that you are not using Microsoft Edge.";
      secondText =
        "For best experience using pulseTracker, we recommend Microsoft Edge and turning on cookies:";
    }
  }
  return { firstSubtitle, secondText };
})(window.navigator.userAgent.toLowerCase());

const app = (
  <CookiesLayout>
    <StyledCookiesContainer id="cookies-container">
      <div className="flex justify-center items-center mt-7xl">
        <StyledCookiesLogo src={pulseTracker} alt="pulseTracker" />
      </div>
      <div className="flex flex-col justify-start items-center container pl-8xl mt-3xl">
        <div className="flex justify-start items-center container my-xl">
          <StyledCookiesWarning>
            <McdIcon marginBottom="4px" icon="warning_amber" size="xxlarge" />
          </StyledCookiesWarning>
          <div className="flex flex-col">
            <McdTypography fontSize={5} classes="text-bluegrey-600">
              {browserType.firstSubtitle}
            </McdTypography>
            <McdTypography fontSize={5} classes="text-bluegrey-600">
              {browserType.secondText}
            </McdTypography>
          </div>
        </div>
        <div className="flex justify-start items-center container my-xl">
          <StyledCookiesStep>
            <McdTypography
              fontSize={7}
              fontWeight="bold"
              classes="text-bluegrey-600"
            >
              1
            </McdTypography>{" "}
          </StyledCookiesStep>
          <McdTypography fontSize={6} classes="text-bluegrey-600">
            {firstStep}
          </McdTypography>
          <McdIcon
            classes="cookie-menu text-bluegrey-600"
            size={"xlarge"}
            icon="more_horiz"
          />
          <McdTypography fontSize={6} classes="text-bluegrey-600">
            {firstAdditionalStep}
          </McdTypography>
        </div>
        <div className="flex justify-start items-center container my-xl">
          <StyledCookiesStep>
            <McdTypography
              fontSize={7}
              fontWeight="bold"
              classes="text-bluegrey-600"
            >
              2
            </McdTypography>{" "}
          </StyledCookiesStep>
          <McdTypography fontSize={6} classes="text-bluegrey-600">
            {secondStep}
          </McdTypography>
        </div>
        <div className="flex justify-start items-center container my-xl">
          <StyledCookiesStep>
            <McdTypography
              fontSize={7}
              fontWeight="bold"
              classes="text-bluegrey-600"
            >
              3
            </McdTypography>{" "}
          </StyledCookiesStep>
          <McdTypography fontSize={6} classes="text-bluegrey-600">
            {thirdStep}
          </McdTypography>
        </div>
        <div className="flex justify-start items-center container my-xl">
          <StyledCookiesStep>
            <McdTypography
              fontSize={7}
              fontWeight="bold"
              classes="text-bluegrey-600"
            >
              4
            </McdTypography>{" "}
          </StyledCookiesStep>
          <McdTypography fontSize={6} classes="text-bluegrey-600">
            {fourthStep}
          </McdTypography>
        </div>
      </div>
    </StyledCookiesContainer>
  </CookiesLayout>
);

root.render(app);
