import { AZURE_ENDPOINT, MS_GRAPH_ME_ENDPOINT } from "../constants";
import { computeURL, fetch, fetchOptions } from "../utils/fetch";
export const CLIENT_ID = "azure.clientId";
export const TENANT_ID = "azure.tenantId";

export const loginRequest = {
  scopes: [],
};

export const graphConfig = {
  graphMeEndpoint: MS_GRAPH_ME_ENDPOINT,
};

export const securityLogin = async () => {
  const url = computeURL("security", "login");
  const method = "GET";
  return fetch(url, fetchOptions({ method }));
};

export const getIsAzure = async () => {
  const method = "GET";

  return fetch(AZURE_ENDPOINT, fetchOptions({ method, isPublic: true }));
};
