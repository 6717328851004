export const getZoom = () => {
  let zoom = (window.innerHeight / 1020) * 100;

  if (window.innerHeight < 650) {
    return 75;
  }

  if (zoom > 100) {
    return 100;
  }

  return zoom;
};
